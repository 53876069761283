import React from "react";
import "@learnio-as/web-trial/dist/index.css";
import NoSSR from 'react-no-ssr';
import {Steps } from "@learnio-as/web-trial";
export default function TrialSteps(){
    return(
       <div>
           <NoSSR>
           <Steps location="/trial-steps" />
           </NoSSR>
       </div>
    );
}